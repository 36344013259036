html {
  font-size: $base-fz + px;
  line-height: 1.35;
  color: $c-text;
  font-family: 'Assistant', sans-serif;
  @include sm {
    font-size: $base-fz-sm + px; } }

body {
  background-color: $white;
  min-width: 320px;
  @include no-scroll; }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  $this: &;
  &--loaded:not(#{$this}--no-fade-in) {
    animation: fadeIn 1s; } }


.label {
  display: inline-block;
  background-color: $accent;

  font-weight: 700;
  font-size: em(25);
  text-transform: uppercase;

  padding: 0 .3em 0 .5em;

  @include sm {
    font-size: em(16, $base-fz-sm); } }

.logo {
  display: inline-block;
  font-size: 0;
  @include lg {
    width: 150px; } }

