.form {
  &__title {
    color: $white;
    margin-bottom: rem(30); }

  &__field {
    @include notlast {
      margin-bottom: rem(30); } }

  &__checkbox {
    margin-top: rem(-15); } }
