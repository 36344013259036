.title {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;

  &--h1 {
    font-size: em(50);
    @include sm {
      font-size: em(26, $base-fz-sm); } }


  &--h2 {
    font-size: em(28); } }

.list {
  font-weight: 700;
  margin-right: rem(-60);
  @include xl {
    margin-right: rem(-40); }

  &__item {
    display: flex;
    align-items: center;
    @include notlast {
      margin-bottom: rem(10); } }

  &__icon {
    margin-left: em(35);
    @include xl {
      margin-left: em(15); } } }
