.input {
  color: $black;
  font-size: em(15);

  #{$text-inputs} {
    width: 100%;
    background-color: $dark-aux;
    height: rem(52);
    padding: .2em em(20);
    border-radius: 3px;
    @include placeholder {
      color: inherit; } }

  &--checkbox {
    position: relative;
    padding-right: 1.3em;
    user-select: none;
    input[type="checkbox"] {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      & + span {
        &::before {
          content: '';
          border: 1px solid $white;
          width: .9em;
          height: .9em;
          top: .3em;
          right: 0;
          background-color: transparent;
          position: absolute;
          display: block; } }
      &:checked + span {
        &::after {
          content: '';
          @include icon-arrow(.6, .3, .05, $white, -45, em);
          top: .5em;
          right: .15em;
          position: absolute;
          display: block; } } } } }

