$base-fz:    18;
$base-fz-sm: 14;

$text-inputs: "[type='color']", "[type='date']", "[type='datetime']", "[type='datetime-local']", "[type='email']", "[type='month']", "[type='number']", "[type='password']", "[type='search']", "[type='tel']", "[type='text']", "[type='time']", "[type='url']", "[type='week']", "input:not([type])", "textarea";
$check-inputs: "[type='checkbox']", "[type='radio']";

$c-text:   #231f20;

$black:    #000;
$white:    #fff;
$accent:   #ee7533;
$dark:     #58585a;
$dark-aux: #a9a9aa;

@mixin windowHeight($offset: 0px) {
  height: calc(100vh - #{$offset});
  height: calc((var(--vh, 1vh) * 100) - #{$offset}); }
