.btn {
  display: inline-block;
  text-align: center;
  text-transform: uppercase;

  color: $white;
  background-color: $accent;

  font-size: em(20);
  font-weight: 700;

  width: 100%;
  padding: rem(11) 1rem;

  @include tr(opacity .3s);
  @include hover {
    opacity: .8; } }
