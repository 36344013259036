$icons: (0:0);
$icons: map-merge($icons,('check': (X: -71px, Y:0px, W: 24px, H: 22px, TW: 95px, TH: 61px, IMG: '../img/sprite.png')));
$icons: map-merge($icons,('phone': (X: 0px, Y:0px, W: 61px, H: 61px, TW: 95px, TH: 61px, IMG: '../img/sprite.png')));


// Gets an attribute from the sass map
@function icon-attr($icon, $attr) {
  $icon: map-get($icons, $icon);
  @return map-get($icon, $attr); }

@mixin sprite($iconName) {
  background-image: url(icon-attr($iconName, IMG));
  width: icon-attr($iconName, W);
  height: icon-attr($iconName, H);
  background-position: icon-attr($iconName, X) icon-attr($iconName, Y); }
@mixin sprite-position($iconName) {
  background-position: icon-attr($iconName, X) icon-attr($iconName, Y); }
@mixin sprite-retina($iconName) {
  background-image: url(icon-attr($iconName, IMG));
  $width: icon-attr($iconName, W);
  $height: icon-attr($iconName, H);
  width: $width/2;
  height: $height/2;
  $x: icon-attr($iconName, X);
  $y: icon-attr($iconName, Y);
  background-position: $x/2 $y/2;
  $tw: icon-attr($iconName, TW);
  $th: icon-attr($iconName, TH);
  background-size: $tw/2 $th/2; }


@mixin s($i) {
  @include sprite($i); }
@mixin sp($i) {
  @include sprite-position($i); }
@mixin sr($i) {
  @include sprite-retina($i); }

//
//.icon-check
//  width: 24px
//  height: 22px
//  background-image: url('../img/sprite.png')
//  background-position: -71px 0px
//
//.icon-phone
//  width: 61px
//  height: 61px
//  background-image: url('../img/sprite.png')
//  background-position: 0px 0px
//
